<script setup>
import { serviziNav } from '@/graphql/queries/servizi-nav.js'

const props = defineProps({
  triggerSource: {
    type: String,
    default: '',
  },
})

const { triggerSource } = toRefs(props)

watch(triggerSource, (newValue) => {
  // console.log('triggerSource:', newValue)
})

const { locale } = useI18n()

const variables = computed(() => ({
  locale: locale.value,
}))

const { data } = await useGraphql(serviziNav, variables)

if (
  !data.value ||
  !data.value.allInfanziaServizios ||
  !data.value.allPrimariaServizios
) {
  throw createError({ statusCode: 404 })
}

const lista = computed(() => {
  if (triggerSource.value === 'infanzia') {
    return data.value.allInfanziaServizios || []
  } else if (triggerSource.value === 'primaria') {
    return data.value.allPrimariaServizios || []
  } else {
    return []
  }
})

const currentLocalePath = computed(() => {
  return triggerSource.value === 'infanzia' ? 'infanzia-slug' : 'primaria-slug'
})

const emit = defineEmits(['close'])
</script>

<template>
  <div v-if="lista.length" class="nav-sub-menu row-130-25">
    <div v-for="(item, index) in lista" :key="index" class="column-1">
      <nuxt-link
        v-if="item.slug"
        class="text-4"
        :to="
          localePath({ name: currentLocalePath, params: { slug: item.slug } })
        "
        @click="emit('close')"
      >
        <div class="img-container">
          <DefaultPicture
            :image="{ custom: item.immagine.responsiveImage }"
            :lazy-load="true"
            alt="Istituto Canossiano"
          />
        </div>
      </nuxt-link>
      <div class="servizio">
        <DefaultLink
          v-if="item.titolo"
          class="icon-link text-s-1"
          :text="item.titolo"
          :content="
            localePath({
              name: currentLocalePath,
              params: { slug: item.slug },
            })
          "
          @click="emit('close')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuOverlay',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
